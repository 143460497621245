<template>
  <f7-page class="productview-vineyard-page">
    <template #fixed>
      <ProductNavigationComponent title="Coastal Estate Vineyard" type="back" :search="false" />
    </template>

    <section class="vineyard-container">
      <div class="image-container">
        <img src="@/assets/images/demo/vineyard/2.jpg" :alt="$t.getTranslation('LBL_VINYARD_IMAGE')" loading="lazy"/>
      </div>

      <div class="info">
        <img src="@/assets/images/demo/merchants/1.jpg" class="logo" :alt="$t.getTranslation('LBL_VINYARD_IMAGE')" loading="lazy" />
        <h1>Coastal Estate Vineyard</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure saepe, in voluptatibus nihil libero numquam quam at omnis natus enim consectetur.</p>
      </div>
    </section>

    <section class="product-container">
      <div class="title">
        <h1>{{ $t.getTranslation("LBL_PRODUCTS") }}</h1>
      </div>
      <template v-for="(product, ind) in ['Cabernet Sauvignon', 'Syrah Sauvignon', 'Pinot Grigio']" :key="'pr_' + ind">
        <ProductListCardComponent :data="{ image: ind, name: product }" />
      </template>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import { useStore } from "@/store";

// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";
// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));
const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));

export default defineComponent({
  name: "ProductViewVineyardPage",
  components: {
    ProductNavigationComponent,
    ProductListCardComponent,
  },
  props: { f7router: Object },
  setup(props) {

    const store = useStore(); 
    
    return {};
  },
});
</script>
